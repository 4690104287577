import { Button, Modal } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import Slider from "../components/slider/Slider";
import ControlPanel from "../components/controls/ControlPanel";

export default function MyVerticallyCenteredModal(props) {
  const [percentage, setPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const audioRef = useRef();

  useEffect(() => {
    if (!props.show) {
      setPercentage(0);
      setIsPlaying(false);
      setDuration(0);
      setCurrentTime(0);
    }
  }, [props]);

  const onChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setPercentage(e.target.value);
  };

  const play = () => {
    const audio = audioRef.current;
    audio.volume = 0.1;

    if (!isPlaying) {
      setIsPlaying(true);
      audio.play();
    }

    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ marginLeft: "10px" }}
          id="contained-modal-title-vcenter"
        >
          Recording Audio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="row">
          <div class="col">
            <h2 style={{ margin: " 0 0 20px 10px" }}>
              Audio {props.recNumber}
            </h2>
          </div>
          <div class="col">
            <h4 style={{ textAlign: "center", margin: " 5px 0 20px 10px" }}>
              {new Date(props.recording.createdAt).toLocaleString()}
            </h4>
          </div>
        </div>

        <Slider percentage={percentage} onChange={onChange} />
        <audio
          ref={audioRef}
          onTimeUpdate={getCurrDuration}
          onLoadedData={(e) => {
            setDuration(e.currentTarget.duration.toFixed(2));
          }}
          // src={props.recording.recordingFile}
        >
            <source src={props.recording.recordingFile} type="audio/wav"/>
           {/* <source src={props.recording.recordingFile} type="audio/mp3"></source>
          <source src={props.recording.recordingFile} type="audio/ogg"></source> Your browser
          does not support the audio element. */}
        </audio>
        <ControlPanel
          play={play}
          isPlaying={isPlaying}
          duration={duration}
          currentTime={currentTime}
        />
      </Modal.Body>
      <Modal.Footer>
        <a
          href={props.recording.recordingFile}
          class="btn"
          style={{ background: "#47D1CC", color: "white" }}
        >
          Download
        </a>
        <Button
          onClick={props.onHide}
          style={{ background: "#47D1CC", color: "white" }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
