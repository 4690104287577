//DOT Marker Code

// import React, { useEffect, useState } from "react";
// import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
// import midLoc from "../assets/Icons/middleLoc.png";
// import locStart from "../assets/Icons/Start.png";
// import locEnd from "../assets/Icons/End.png";
// import latest from "../assets/Icons/Latest.png";
// // import locStart from '../assets/Icons/startLoc.png'
// // import locEnd from '../assets/Icons/stopLoc.png'

// function MapBle(param) {
//   let locations = [];
//   let mapData = param?.passId?.alarmLocationDetails;

//   // if (mapData.length >= 1) {
//   //   for (let i = 0; i < mapData.length; i++) {
//   //     if (mapData[i].latitude != "") {
//   //       locations.push({
//   //         id: i,
//   //         lat: mapData[i].latitude,
//   //         lng: mapData[i].longitude,
//   //         time: mapData[i].logTime,
//   //       });
//   //     }
//   //   }
//   // } else {
//   //   locations.push({
//   //     lat: "",
//   //     lng: "",
//   //   });
//   // }
//   if (mapData.length >= 1) {
//     for (let i = 0; i < mapData.length; i++) {
//       if (mapData[i].latitude !== "" && !mapData[i].longitude.includes('e')) {
//         locations.push({
//           id: i,
//           lat: mapData[i].latitude,
//           lng: mapData[i].longitude,
//           time: mapData[i].logTime,
//         });
//       }
//     }
//   } else {
//     locations.push({
//       lat: "",
//       lng: "",
//     });
//   }

//   let loclen = locations.length - 1;

//   const hoverDate = (e, coords) => {
//     const latitude = coords.position.lat();
//     const longitude = coords.position.lng();
//     alert(`Latitude: ${latitude}\nLongitude: ${longitude}\nTime: ${coords.title}`);
//   };

//   return (
//     <>
//       {locations.length !== 0 ? (
//         <Map
//           google={param.google}
//           containerStyle={{
//             position: "static",
//             width: "100%",
//             height: "100%",
//           }}
//           style={{
//             width: "100%",
//             height: "100%",
//           }}
//           center={locations[0]}
//           initialCenter={locations[0]}
//           zoom={locations.length === 1 ? 18 : 14}
//           disableDefaultUI={true}
//           mapTypeControl={true}
//           mapTypeControlOptions={{
//             style: param.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
//             position: param.google.maps.ControlPosition.TOP_RIGHT,
//           }}
//         >
//           {locations.map((coords, i) => (
//             <Marker
//               key={coords.id}
//               icon={{
//                 url: midLoc,
//               }}
//               position={coords}
//               title={new Date(coords.time).toLocaleString([], { hour12: true })}
//               onClick={(e, coords) => {
//                 hoverDate(new Date(coords.time).toLocaleString([], { hour12: true }), coords);
//               }}
//             />
//           ))}

//           <Marker
//             name={"Start position"}
//             position={{ lat: locations[0].lat, lng: locations[0].lng }}
//             icon={{
//               url: locStart,
//             }}
//             title={new Date(locations[0].time).toLocaleString([], { hour12: true })}
//             onClick={() => hoverDate(new Date(locations[0].time).toLocaleString([], { hour12: true }))}
//           />

//           {param?.isEventStop ? (
//             <Marker
//               name={"End position"}
//               position={{ lat: locations[loclen].lat, lng: locations[loclen].lng }}
//               icon={{
//                 url: locEnd,
//               }}
//               title={new Date(locations[loclen].time).toLocaleString([], { hour12: true })}
//               onClick={() => hoverDate(new Date(locations[loclen].time).toLocaleString([], { hour12: true }))}
//             />
//           ) : (
//             <Marker
//               name={"End position"}
//               position={{ lat: locations[loclen].lat, lng: locations[loclen].lng }}
//               icon={{
//                 url: latest,
//               }}
//               title={new Date(locations[loclen].time).toLocaleString([], { hour12: true })}
//               onClick={() => hoverDate(new Date(locations[loclen].time).toLocaleString([], { hour12: true }))}
//             />
//           )}
//         </Map>
//       ) : (
//         "No Data Found"
//       )}
//     </>
//   );
// }

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyBW0xHIrT1e7VtoO7GsldiWk3p98QxSmm4",
// })(MapBle);

//poliline Code

import React from "react";
import { Map, GoogleApiWrapper, Polyline, Marker } from "google-maps-react";
import locStart from "../assets/Icons/Start.png";
import locEnd from "../assets/Icons/End.png";
import latest from "../assets/Icons/Latest.png";

function MapBle(param) {
  const mapData = param?.passId?.alarmLocationDetails;
  const endDateLog = param?.passId?.userAlertData?.endedAt
  // console.log('param', param?.passId?.userAlertData?.endedAt, logTime)

  // const locations = mapData
  //   ? mapData
  //       .filter((coords) => coords.latitude !== "" && !coords.longitude.includes("e"))
  //       .map((coords, index) => {
  //         if (new Date(endDateLog) <= new Date(coords?.logTime)) {
  //           return ({
  //           id: index,
  //           lat: parseFloat(coords.latitude),
  //           lng: parseFloat(coords.longitude),
  //           time: coords.logTime,
  //         })
  //         }
  //     })
  //   : [];
  const locations = mapData
  ? endDateLog !== "" ? mapData
      .filter(
        (coords) =>
          coords.latitude !== "" &&
          !coords.longitude.includes("e") &&
          new Date(endDateLog) >= new Date(coords?.logTime)
      )
      .map((coords, index) => ({
        id: index,
        lat: parseFloat(coords.latitude),
        lng: parseFloat(coords.longitude),
        time: coords.logTime,
      })) : 
      mapData
      .filter(
        (coords) =>
          coords.latitude !== "" &&
          !coords.longitude.includes("e")
      )
      .map((coords, index) => ({
        id: index,
        lat: parseFloat(coords.latitude),
        lng: parseFloat(coords.longitude),
        time: coords.logTime,
      }))
  : [];

  const loclen = locations.length - 1;

  const hoverDate = (e, coords) => {
    const latitude = coords.position.lat();
    const longitude = coords.position.lng();
    alert(`Latitude: ${latitude}\nLongitude: ${longitude}\nTime: ${coords.title}`);
  };

  return (
    <>
      {locations.length !== 0 ? (
        <Map
          google={param.google}
          containerStyle={{
            position: "static",
            width: "100%",
            height: "100%",
          }}
          style={{
            width: "100%",
            height: "100%",
          }}
          center={locations[locations.length - 1]}
          initialCenter={locations[locations.length - 1]}
          zoom={locations.length === 1 ? 18 : 14}
          disableDefaultUI={true}
          mapTypeControl={true}
          mapTypeControlOptions={{
            style: param.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: param.google.maps.ControlPosition.TOP_RIGHT,
          }}
        >
          <Polyline
            path={locations}
            options={{
              strokeColor: "#0000FF",
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
          />

          <Marker
            name={"Start position"}
            position={{ lat: locations[0].lat, lng: locations[0].lng }}
            icon={{
              url: locStart,
            }}
            title={new Date(locations[0].time).toLocaleString([], { hour12: true })}
            onClick={() => hoverDate(new Date(locations[0].time).toLocaleString([], { hour12: true }))}
          />

          {param?.isEventStop ? (
            <Marker
              name={"End position"}
              position={{ lat: locations[loclen].lat, lng: locations[loclen].lng }}
              icon={{
                url: locEnd,
              }}
              title={new Date(locations[loclen].time).toLocaleString([], { hour12: true })}
              onClick={() => hoverDate(new Date(locations[loclen].time).toLocaleString([], { hour12: true }))}
            />
          ) : (
            <Marker
              name={"End position"}
              position={{ lat: locations[loclen].lat, lng: locations[loclen].lng }}
              icon={{
                url: latest,
              }}
              title={new Date(locations[loclen].time).toLocaleString([], { hour12: true })}
              onClick={() => hoverDate(new Date(locations[loclen].time).toLocaleString([], { hour12: true }))}
            />
          )}
        </Map>
      ) : (
        "No Data Found"
      )}
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBW0xHIrT1e7VtoO7GsldiWk3p98QxSmm4",
})(MapBle);
